import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { layoutMultilineText, PDFDocument, PDFPage, rgb, StandardFonts, TextAlignment } from 'pdf-lib';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { FundsServiceService } from 'src/app/Services/Utilities/funds-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SubmitFormComponent } from '../cic/submit-form/submit-form.component';
import { NewApplicationComponent } from '../funds/new-application/new-application.component';

@Component({
  selector: 'app-admin-funds',
  templateUrl: './admin-funds.component.html',
  styleUrls: ['./admin-funds.component.css']
})
export class AdminFundsComponent implements OnInit {


  filelist = [];
  sortedfilelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  dateRange: any;
  email: any;
  uuid: any;
  loading = true;
  appliedBy = ''

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    public router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private dateSrv: DateFormatService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public fundService: FundsServiceService
  ) {
    this.spinner.show();

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
      this.uuid = auth.uid;
      this.firestore.collection('StartUp', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
        e.forEach(file => {
          if (file.data().Status != 'Draft') {
            const data = {
              ...file.data(),
              id: file.id,
              fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",
              CreatedDate: new Date(file.data().CreatedDate.toDate())
            }
            this.filelist.push(data);
          }
          this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
          this.loading = false;
          this.length = this.filelist.length;
        })
      });
      this.spinner.hide();
    });

  }

  ngOnInit() {
  }

  view(file) {
    this.fundService.setFile(file);
    this.router.navigate(['/Admin/suso/View'])
  }

  SetupFile() {
    this.loading = true;

    this.filelist = [];
    this.firestore.collection('StartUp', ref => ref.orderBy('CreatedDate', 'desc')).get().forEach(e => {
      e.forEach(file => {
          if (file.data().Status != 'Draft') {
            const data = {
          ...file.data(),
          id: file.id,
          fileName: file.data().FileNames ? file.data().FileNames.join('\n') : "Not yet upload",

          CreatedDate: new Date(file.data().CreatedDate.toDate())
        }
        this.filelist.push(data);
        this.sortedfilelist = this.filelist.slice(this.offset, (this.offset + this.pageSize));
        this.loading = false;
        this.length = this.filelist.length;
      }
      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedfilelist = this.filelist.slice();
    this.sortFile();
    this.limitFile();
  }

  limitFile() {
    this.sortedfilelist = this.sortedfilelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedfilelist = this.filelist.slice();
    if (this.status || this.fileName || this.dateRange) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !(this.status || this.fileName || this.dateRange)) {
      this.sortedfilelist = this.filelist.slice();
      this.limitFile();
      return;
    }

    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'createdBy': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
    this.limitFile();
  }

  sortFile() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedfilelist = this.sortedfilelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'file': return this.compare(a.fileName, b.fileName, isAsc);
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'date': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'createdBy': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  reset() {
    this.fileName = '';
    this.status = 'All';
    this.dateRange = '';
    this.SetupFile();
  }
  search() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u =>
      String(u.fileName).toLowerCase().includes(this.fileName.toLowerCase()) &&
      String(u.CreatedBy).toLowerCase().includes(this.appliedBy.toLowerCase())
    );

    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }
    this.length = this.sortedfilelist.length;
    this.sortFile();
    this.limitFile();
  }

  DS() {
    let startDate;
    let endDate;

    this.sortedfilelist = this.filelist.filter(u =>
      String(u.fileName).toLowerCase().includes(this.fileName.toLowerCase()) &&
      String(u.CreatedBy).toLowerCase().includes(this.appliedBy.toLowerCase())
    );
    if (this.status !== 'All')
      this.sortedfilelist = this.sortedfilelist.filter(u => u.Status === this.status);
    if (typeof (this.dateRange) === 'object') {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        startDate = this.dateRange.startDate.toDate();
        endDate = this.dateRange.endDate.toDate();
        this.sortedfilelist = this.sortedfilelist.filter(u =>
          u.CreatedDate.getTime() >= startDate.getTime() && u.CreatedDate <= endDate.getTime());

      }
    }

    this.length = this.sortedfilelist.length;
    this.sortFile();
  }

  assign(lorry, id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Assign this File ' + id + ' to ' + lorry.name
    };
    lorry.totalNum++;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection('Files').doc(id)
          .update({ LorryType: lorry.name, No: lorry.totalNum }).then(s => {
            const r = this.sortedfilelist.find(d => d.id === id);
            r.LorryType = lorry.name;
            this.firestore.collection('lorries').doc(lorry.id).update({ totalNum: lorry.totalNum });

          });
      }
    });
  }

  status = "All"
  fileName = '';
  applicationList = [];
  SelectStatus(status) {
    this.status = status;
  }

  dateRangeChange(e) {


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  FillForm() {
    this.dialog.open(NewApplicationComponent, {
      width: '95vw',
      height: '90%',
      disableClose: true,
      data: true
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });

  }

  SubmitForm(file) {
    this.dialog.open(SubmitFormComponent, {
      width: '70%',
      height: '80%',
      disableClose: true,
      data: file.id
    }).afterClosed().subscribe(r => {
      this.SetupFile();
    });
  }

  private dateFormat = new DateFormatService();

  edit(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to shortlist this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("StartUp").doc(file.id).update({ Status: "Shortlisted", UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Shortlisted";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been shortlist by Email: " + this.email,
            date: new Date()
          };
          await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Shortlist Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  approve(file, value) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to update this application to ' + value + '?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("StartUp").doc(file.id).update({ Status: value, UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = value;

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been updated by Email: " + this.email + ". Status: " + value,
            date: new Date()
          };
          await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Updated Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();
        }
      }
    })


  }

  cancel(file) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm want to reject this application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async data => {
      if (data) {
        try {
          this.spinner.show();
          await this.firestore.collection("StartUp").doc(file.id).update({ Status: "Not Successful", UpdatedDate: new Date(), UpdatedBy: this.email });
          file.Status = "Not Successful";

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: "Application " + file.id + " has been rejected by Email: " + this.email,
            date: new Date()
          };
          await this.firestore.collection('StartUpLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('StartUpLog').doc(dateFormat).collection('User').add(info);


          this.spinner.hide();
          this.toaster.success("Reject Successfully");
        } catch (error) {
          this.toaster.error(error, "Error happen");
          this.spinner.hide();

        }
      }
    })
  }

  pdfDoc: PDFDocument;
  currentX = 0;
  currentY = 0;
  currentPage: PDFPage;
  currentFontSize = 12

  async print(file) {
    this.spinner.show();
    this.pdfDoc = await PDFDocument.create();
    const font = await this.pdfDoc.embedFont(StandardFonts.TimesRoman)

    const fontBold = await this.pdfDoc.embedFont(StandardFonts.TimesRomanBold)

    this.currentPage = this.pdfDoc.addPage();
    this.currentY = 800;
    this.currentX = 50;
    this.currentFontSize = 14;
    this.autoText('Application ID: ' + file.id, fontBold);
    this.currentY = this.currentY-10;
    this.currentFontSize = 12;
    this.autoText('Company Name ', font);
    this.autoText(': ' + file.firstFormGroup.CompanyName,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.autoText('Product Name', font);
    this.autoText(': ' + file.firstFormGroup.ProductName, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('Proposed paid-up capital ', font);
    this.autoText(': ' + file.firstFormGroup.Proposed,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('Applicant Name',font);
    this.autoText(': ' + file.firstFormGroup.Applicant,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('Email ', font);
    this.autoText(': ' + file.firstFormGroup.Email,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('Phone', font);
    this.autoText(': ' + file.firstFormGroup.Phone, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('School', font);
    this.autoText(': ' + file.firstFormGroup.School,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
    this.autoText('Expertise', font);
    this.autoText(': ' + file.firstFormGroup.Expertise, font, true, this.currentY + this.currentFontSize + 4, true, 180, true);

    this.currentFontSize = 16;
    this.currentY = this.currentY-10;
    this.autoText('Technology Description', fontBold);


    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Problem Statement', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.ProblemStatement,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Proposed Solution', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.ProposedSolution,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Product Status', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.ProductStatus,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Product Track Record', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.ProductRecord,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('List of USM IP', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.USMIP,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Innovation Value', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.Innovation,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Technology Benchmarking', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.secondFormGroup.TechnologyBenchmarking,font);

    this.currentFontSize = 16;
    this.currentY = this.currentY-10;
    this.autoText('Business Description', fontBold);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Target Market', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.thirdFormGroup.TargetMarket,font);


    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Go-to-market Strategy', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.thirdFormGroup.GoToMarket,font);

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Business Model', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.thirdFormGroup.BusinessModel,font);

    this.currentPage = this.pdfDoc.addPage();
    this.currentY = 800;

    this.currentFontSize = 16;
    this.currentY = this.currentY-10;
    this.autoText('Management Team', fontBold);

    if(file.fourthFormGroup.Directors.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-10;
      this.autoText('Directors\' Informations', fontBold);
    }

    let i = 1;
    for (const d of file.fourthFormGroup.Directors) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name ', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Identity Number ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.IdentityNumber,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('USM Staff ', font, false, this.currentY, true, 70, true);
      this.autoText( d.USMStaff?': Yes':': No',  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Expertise/Experience ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Experience,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentY = this.currentY-10;
    if(file.fourthFormGroup.Shareholders.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-10;
      this.autoText(' Shareholders\' Informations', fontBold);
    }

    i = 1;
    for (const d of file.fourthFormGroup.Shareholders) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name ', font, true, this.currentY + this.currentFontSize + 4, true, 70, true);
      this.autoText(': ' + d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Identity Number ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.IdentityNumber,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('USM Staff ', font, false, this.currentY, true, 70, true);
      this.autoText( d.USMStaff?': Yes':': No',  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Percentage (%) ', font, false, this.currentY, true, 70, true);
      this.autoText(': ' + d.Percentages,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    if(file.fourthFormGroup.Shareholders.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-5;
      this.autoText(' Total Percentage: ' + this.getTotalPercentage(file), fontBold);
    }


    this.currentY = this.currentY-10;
    if(file.fourthFormGroup.ManagementTeam.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-10;
      this.autoText('Management Teams\' Informations', fontBold);
    }
    i = 1;

    for (const d of file.fourthFormGroup.ManagementTeam) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Name ', font, true, this.currentY + this.currentFontSize + 4, true, 70);
      this.autoText(': ' + d.Name,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Identity Number ', font, false, this.currentY, true, 70);
      this.autoText(': ' + d.IdentityNumber,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('USM Staff ', font, false, this.currentY, true, 70);
      this.autoText( d.USMStaff?': Yes':': No',  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Role ', font, false, this.currentY, true, 70);
      this.autoText(': ' + d.Role,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      this.autoText('Expertise/Experience ', font, false, this.currentY, true, 70);
      this.autoText(': ' + d.Experience,  font, true, this.currentY + this.currentFontSize + 4, true, 180, true);
      i++
    }

    this.currentPage = this.pdfDoc.addPage();
    this.currentY = 800;
    this.currentFontSize = 16;
    this.currentY = this.currentY-10;
    this.autoText('Resources Requirement & Funding Utilization', fontBold);
    this.currentFontSize = 12;
    this.autoText('CIC Spark Fund ', font);
    this.autoText(': ' + file.fifthFormGroup.SparkFund,  font, true, this.currentY + this.currentFontSize + 4, true, 180);


    this.currentY = this.currentY-10;
    if(file.fifthFormGroup.Expenses.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-10;
      this.autoText(' Expenses\' Information', fontBold);
    }

    i = 1;
    for (const d of file.fifthFormGroup.Expenses) {
      this.currentFontSize = 12;
      this.autoText('(' + i + ')', font);
      this.autoText('Type of Expenses ', font, true, this.currentY + this.currentFontSize + 4, true, 70);
      this.autoText(': ' + d.Type,  font, true, this.currentY + this.currentFontSize + 4, true, 230);
      this.autoText('Funding Utilization(RM)', font, false, this.currentY, true, 70);
      this.autoText(': ' + d.Amount,  font, true, this.currentY + this.currentFontSize + 4, true, 230);

      i++
    }

    if(file.fifthFormGroup.Expenses.length > 0){
      this.currentFontSize = 14;
      this.currentY = this.currentY-5;
      this.autoText(' Total : ' + this.getTotalAmount(file), fontBold);
    }

    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Equity : ', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.fifthFormGroup.Equity || 'none',font);


    this.currentFontSize = 14;
    this.currentY = this.currentY-10;
    this.autoText('Product Development', fontBold);
    this.currentFontSize = 12;
    this.autoText(file.fifthFormGroup.ProductDevelopment,font);



    const pdfBytes = await this.pdfDoc.save()
    let f = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(f);
    var anchor = document.createElement("a");
    anchor.download = file.id + ".pdf";
    anchor.href = fileURL;
    anchor.click();
    this.spinner.hide();

  }

  async autoText(text, font, fixedYPosition = false, y = 0, fixedXPosition = false, x = 0 , correctWidth = false) {
    let multiText = layoutMultilineText(text,
      {
        alignment: TextAlignment.Left,
        fontSize: this.currentFontSize,
        font: font,
        bounds: { x: this.currentX, y: this.currentY - this.currentFontSize - 5, width: correctWidth?350: 500, height: 600 }
      })

      if(fixedYPosition)
        this.currentY = y;

      for (let i = 0; i < multiText.lines.length; i++) {
      this.currentPage.drawText(`${multiText.lines[i].text}`, {
        x: fixedXPosition?x: this.currentX,
        y: this.currentY,
        size: this.currentFontSize,
        font: font,
        color: rgb(0, 0, 0)
      })

      if (this.currentY < 80) {
        this.currentPage = this.pdfDoc.addPage();
        this.currentY = 780
      }
      this.currentY = this.currentY - this.currentFontSize - 4;

    }

  }

  getTotalAmount(file){
    let total = 0;
    for (const f of file.fifthFormGroup.Expenses) {
        total += parseFloat(f.Amount);
    }
    return total;
  }

  getTotalPercentage(file){
    let total = 0;
    for (const f of file.fourthFormGroup.Shareholders) {
        total += parseFloat(f.Percentages);
    }
    return total;
  }

}
